.writingTile .writingContent, .textbox .innerTextbox .skills, .writing, .bio, .footer p, html .section, body .section {
  display: flex;
  justify-content: center;
}

.parallax2, .parallax1, .header {
  background-size: cover;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  position: static;
  width: 100%;
  height: 300px;
  z-index: 1;
}

.bio .profilePic {
  object-fit: cover;
  object-position: 100% 0%;
  border-radius: 50%;
}

.textbox {
  opacity: 0;
  animation: fadeLeft 5s 1;
  animation-fill-mode: forwards;
}

.techbox {
  opacity: 0;
  animation: fadeRight 5s 1;
  animation-fill-mode: forwards;
}

.logoContainer, .gridContainer ul li {
  opacity: 0;
  animation: fadeIn 3s 1;
  animation-fill-mode: forwards;
}

.gridContainer ul {
  width: 80%;
  list-style-type: none;
}

a {
  font: "Quattrocento Sans";
  text-decoration: none !important;
}

div {
  font-family: Raleway;
  font: #034;
}

html, body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
html .section, body .section {
  align-items: center;
  height: 800px;
  width: 100%;
  padding: 60px 0px 60px 0px;
}

.App {
  padding: 20px 60px 20px 60px;
  background-color: black;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  overflow: hidden;
  z-index: 10;
}
.App div {
  display: flex;
  justify-content: flex-end;
}
.App .item {
  font: "Quattrocento Sans";
  font-size: 1.2em;
  color: white;
}

.about {
  overflow: hidden;
  height: 100%;
}
.about .image {
  height: 100%;
  width: auto;
  margin-right: 35px;
}

.info {
  color: black;
  font: "Quattrocento Sans";
  font-size: 1.2em;
  width: 80%;
  padding: 100px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
}
.info h1 {
  font: 2.3em bold Raleway;
  color: black;
}

.name {
  font: 5em bold;
}

.header-container {
  position: relative;
  top: 30%;
  left: 15%;
}

.header-content {
  color: white;
  justify-content: center;
  text-align: center;
}

.header {
  background-image: url(/static/media/lightbulb.630467c3.jpg);
  height: 1200px;
}

.floatingContact {
  position: fixed;
  z-index: 100;
  padding: 15px;
  width: auto;
  height: auto;
  bottom: 10px;
  right: 10px;
  background-color: black;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-weight: bolder;
}

.contactModal {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  border: 1px solid white;
}

.contact-child-email {
  width: 60%;
}

.contact-child-subject {
  width: 60%;
}

.contact-child-message {
  height: 50%;
  width: 100%;
}

.parallax1 {
  background-image: url(/static/media/chicago.e4936486.jpg);
  height: 250px;
}

.parallax2 {
  background-image: url(/static/media/brooklyn.ded47280.jpg);
  height: 250px;
}

.tagline {
  font-size: 5em;
  color: white;
  font: Raleway;
  position: relative;
  padding: 70px;
  justify-content: center;
  text-align: center;
  font-weight: 900;
  background-color: rgba(10, 10, 10, 0.4);
  height: 100%;
}

.feature {
  position: relative;
  background-color: rgba(252, 252, 252, 0.1);
}
.feature .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px 20px 10px;
}
.feature .container .row {
  text-align: center;
  width: 100%;
}

.trait {
  margin: 0px 20px 0px 20px;
  font-size: 1.4em;
  font-weight: 1000;
  font: "Quattrocento Sans";
  color: white;
  text-align: left;
}

.footer {
  background-color: black;
  position: static;
  z-index: 10;
  bottom: 0;
  height: 40px;
  width: 100%;
}
.footer p {
  color: white;
  align-content: center;
}

.bio {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 8px solid #034;
  position: absolute;
  transition: transform 500ms ease-in-out;
}
.bio .profilePic {
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: transform 1500ms ease-in-out;
}
.bio .insideQualities {
  position: relative;
  left: 150px;
  top: 85px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: transform 500ms ease-in-out;
}
.bio .qualities {
  width: 50px;
  height: 50px;
  transition: transform 5000ms ease-in-out;
}

.socialContainer .social {
  height: 35px;
  width: 35px;
  margin: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.education {
  display: flex;
  justify-content: flex-end;
}
.education .slide {
  position: absolute;
  display: flex;
  justify-content: center;
}
.education img {
  height: 600px;
  width: 600px;
  object-fit: contain;
}
.education .image {
  visibility: hidden;
}
.education .descript {
  visibility: hidden;
}
.education .current1 {
  position: relative;
  right: 10%;
  opacity: 0;
  animation: fadeDown 3s 1;
  animation-fill-mode: forwards;
}
.education .current2 {
  position: relative;
  right: 45%;
  font: 4em solid Raleway;
  opacity: 0;
  animation: fadeUp 3s 1;
  animation-fill-mode: forwards;
  transform: rotate(90deg);
}
.education p {
  width: 30%;
  position: relative;
  left: 400px;
  font: 1.5em "Quattrocento Sans";
}

.gridContainer {
  padding-top: 30px;
  position: relative;
  right: 250px;
}
.gridContainer .tile {
  width: 650px;
  height: 180px;
  padding: 15px;
  margin: 15px;
  background-color: rgba(24, 50, 99, 0.1);
}
.gridContainer .tile h3 {
  font-family: Raleway;
}
.gridContainer ul {
  column-count: 3;
}
.gridContainer ul h3 {
  animation: fadeLeft 3s 1;
  animation-fill-mode: forwards;
}
.gridContainer ul li {
  font: 1.1em "Quattrocento Sans";
}

.item:hover {
  border-bottom: 3px solid white;
  font-size: 1.4em;
  font-weight: bold;
}

.writing {
  padding: 60px 0px 60px 0px;
  align-items: center;
  width: 100%;
  height: auto;
}
.writing .panel {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-gap: 10px;
  padding: 0px 20px 0px 20px;
}
.writing .panel .section1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.writing .panel .section1 .sectionName {
  text-transform: uppercase;
  font: 20px solid black;
  font-weight: bolder;
}
.writing .panel .section2 {
  grid-column-start: 2;
  grid-column-end: 4;
  padding: 50px 30px 50px 30px;
}
.writing .panel .section2 li {
  list-style-type: square;
}
.writing .panel .section2 li ._item {
  font-size: 15px;
  color: black;
}
.writing .panel .section2 li ._item:hover {
  border-bottom: 2px solid black;
}
.writing .panel .section2 li .date {
  font: 15px solid grey;
  font-weight: bolder;
}
.writing .container {
  justify-content: center;
  align-content: center;
  display: grid;
  grid-template-columns: repeat(3, auto);
  transition: 500ms ease;
}

.projects {
  height: 100%;
  padding: 30px 0px 30px 0px;
  display: flex;
  justify-content: center;
}
.projects .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transition: 500ms ease;
}

.projectTile {
  height: 300px;
  width: 300px;
  margin: 10px;
  cursor: pointer;
}
.projectTile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card:hover .card-image {
  opacity: 0.3;
}

.card:hover .overlay {
  opacity: 1;
  background-color: rgba(10, 10, 10, 0.7);
}

.highlightedOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: #0a0a0a;
}

.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  padding: 15px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  overflow: scroll;
}
.overlay p {
  color: white;
  font: 1.3em "Quattrocento Sans";
}

.overlay-icons {
  display: flex;
  justify-content: center;
  position: relative;
}

.projectDetails {
  width: 60%;
}
.projectDetails img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.projectDetails .projectDescription {
  height: 50%;
  padding: 50px 0px 50px 0px;
  display: flex;
}
.projectDetails .projectDescription .describe {
  padding: 0px 25px 0px 25px;
}

.navigationIcons {
  width: auto;
  height: 100%;
  padding: 30px 0px 30px 0px;
}
.navigationIcons img {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.material-icons {
  font-size: 40px;
  color: white;
  margin: 5px;
}

.row {
  height: 290px;
}

.card-image {
  height: 100%;
}

.tech {
  display: flex;
}

.logoContainer {
  height: auto;
  width: 600px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.logoContainer .logo {
  width: 90px;
  height: 90px;
  object-fit: contain;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.techbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  padding: 50px;
  background-color: rgba(252, 154, 74, 0.6);
  transition: 500ms ease;
}
.techbox .techList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
}
.techbox ul {
  column-count: 3;
  align-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  height: 50%;
  padding: 20px;
}
.techbox ul li {
  font: 1.3em "Quattrocento Sans";
  width: 80%;
  text-align: center;
  line-height: 1.3em;
  margin-left: auto;
  margin-right: auto;
}

.textbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 10%;
  height: 100%;
  width: 50%;
  padding: 30px;
  margin-right: 100px;
  background-color: rgba(2, 2, 2, 0.4);
  transition: 1500ms ease;
}
.textbox .innerTextbox .skills {
  color: black;
  cursor: pointer;
}

.writingTile {
  height: 325px;
  width: 400px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}
.writingTile img {
  height: 80%;
  width: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
}
.writingTile img:hover {
  transform: scale(1.1);
}
.writingTile .writingContent {
  align-content: center;
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 1.4em;
  padding: 7px;
  height: 20%;
  transition: all 0.2s ease-in-out;
}
.writingTile .writingContent:hover {
  font-weight: bolder;
  text-transform: uppercase;
}

.grow {
  flex-grow: 3;
  font-size: 3.5em;
  font-weight: bold;
}

.grow2 {
  flex-grow: 3;
  font-size: 3.5em;
  font-weight: bold;
  transition: transform 500ms ease-in-out;
  transform: translate(175px, 0px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    margin-left: 300px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
@keyframes fadeRight {
  0% {
    opacity: 0;
    margin-right: 300px;
  }
  100% {
    opacity: 1;
    margin-right: 0px;
  }
}
@keyframes fadeDown {
  0% {
    opacity: 0;
    margin-bottom: 300px;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    margin-top: 300px;
  }
  100% {
    opacity: 1;
  }
}
.transform {
  transform: translate(150px, 0px);
}

.transform2 {
  transform: translate(150px, 0px);
}

@media (min-width: 768px) and (max-width: 1366px) {
  html .section {
    height: auto;
    width: 100%;
  }

  .home, .about, .tech, .education {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .about .image {
    position: relative;
    height: 300px;
    width: 300px;
    top: 30px;
    right: 0px;
    z-index: 1;
    object-fit: cover;
    object-position: 100% 0%;
    border-radius: 50%;
  }
  .about .info {
    margin: 0px;
    width: 100%;
    font-size: 1em;
  }

  .bio {
    position: relative;
    top: 0px;
    height: 220px;
    width: 200px;
  }
  .bio .insideQualities {
    top: 50px;
    left: 100px;
  }

  .education {
    height: 800px;
  }
  .education img {
    height: 300px;
    width: 300px;
  }
  .education .current1 {
    right: 0px;
  }
  .education .current2 {
    right: 300px;
    bottom: 35px;
    transform: rotate(0deg);
  }
  .education p {
    left: 250px;
    bottom: 50px;
    font: 1.2em "Quattrocento Sans";
  }

  .gridContainer {
    display: flex;
    justify-content: center;
    position: none;
    right: 0;
    top: 10px;
    height: auto;
    padding: 0px;
  }
  .gridContainer .tile {
    text-align: center;
    width: 350px;
    height: 300px;
  }
  .gridContainer .tile ul {
    column-count: 1;
  }

  .logoContainer {
    width: auto;
    height: 60%;
    align-items: center;
  }
  .logoContainer .logo {
    width: auto;
  }

  .socialContainer .social {
    height: 25px;
    width: 25px;
  }

  .textbox {
    width: 100%;
    height: 40%;
    position: none;
    bottom: 70px;
    right: 0px;
    padding: 50px 200px 50px 200px;
    display: flex;
    justify-content: center;
    margin: 0px;
  }
  .textbox ul {
    column-count: 4;
  }

  .techbox {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 114, 6, 0);
  }
  .techbox .techList {
    width: auto;
  }
  .techbox ul {
    height: auto;
    width: 100%;
  }
  .techbox ul li {
    width: 30%;
  }

  .trait {
    font-size: 1.3em;
  }

  .transform {
    transform: translate(-100px, 0px);
  }

  .writing .container {
    grid-template-columns: repeat(2, auto);
  }
}
@media (max-width: 767px) {
  html .section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 30px;
  }

  .App {
    padding: 0px;
  }

  .about, .home, .writing, .projects, .education {
    height: auto;
    width: auto;
  }

  body {
    text-align: center;
    height: 100%;
    width: 100%;
  }

  .about {
    width: 100%;
  }
  .about .image {
    height: 200px;
    width: 200px;
    z-index: 1;
    object-fit: cover;
    object-position: 100% 25%;
    border-radius: 50%;
    margin: 30px;
  }
  .about .info {
    margin: 0px;
    padding: 0px;
    position: none;
    font-size: 1.1em;
    width: 100%;
  }

  .education {
    height: 800px;
  }
  .education img {
    height: 200px;
    width: 200px;
  }
  .education .current1 {
    right: 0px;
  }
  .education .current2 {
    right: 0px;
  }
  .education p {
    padding: 150px 0px 120px 0px;
    left: 0px;
    top: 200px;
    width: 100%;
    font: 1.2em "Quattrocento Sans";
  }

  .header-container {
    position: none;
    left: 0%;
    display: flex;
    justify-content: center;
  }

  .header-content {
    width: 300px;
  }
  .header-content .name {
    font-size: 4em;
  }

  .logoContainer {
    width: 400px;
    padding: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .logoContainer .logo {
    width: 75px;
    height: 75px;
  }

  .socialContainer .social {
    height: 25px;
    width: 25px;
  }

  .tagline {
    font-size: 4em;
  }

  .textbox {
    width: 100%;
    height: 40%;
    margin: 0px;
    padding: 50px 200px 50px 200px;
    display: flex;
    justify-content: center;
  }
  .textbox ul {
    column-count: 4;
  }

  .techbox {
    width: 100%;
    padding: 50px 100px 50px 100px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 114, 6, 0);
  }
  .techbox .techList {
    width: 400px;
  }
  .techbox ul {
    height: 100%;
    width: 100%;
    padding: auto;
  }

  .trait {
    font-size: 1.2em;
    margin: 10px;
  }

  .writing .container {
    grid-template-columns: repeat(1, auto);
  }
}

/*# sourceMappingURL=styles/Version1.css.css.map */

.nav {
  display: flex;
  justify-content: space-between;
}

.about {
  padding: 60px 0px;
}

body {
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: #eef0f7;
}

.nav {
  padding: 40px 0px;
  align-items: baseline;
}
.nav .leftNav {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  color: #22424d;
}
.nav .leftNav .name {
  font: Raleway;
  font-size: 50px;
}
.nav .leftNav .page {
  font: Raleway;
  font-size: 40px;
}

.navLink, .paragraphLink {
  font: "Quattrocento Sans";
  text-decoration: none !important;
  color: #967b02;
  font-size: 20px;
  padding: 10px;
}

.paragraphLink {
  padding: 0px;
}

.paragraphLink:hover {
  color: #967b02;
  border-bottom: 2px solid #415a5f;
}

section {
  font-style: Raleway;
  font-size: 20px;
  color: #415a5f;
}

.writing li {
  list-style-type: square;
}
.writing li ._item {
  font-size: 20px;
  color: #22424d;
}
.writing li ._item:hover {
  border-bottom: 2px solid #22424d;
}
.writing li .date,
.writing li .pub {
  font-size: 15px;
  color: #967b02;
  font-weight: bolder;
}

/*# sourceMappingURL=Version2.css.map */

